import React, { useState, useEffect, useRef } from "react";
import * as faceapi from "face-api.js";
import { apps } from './config/firebaseAll';
import { auth } from './config/firebase';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { log } from "@tensorflow/tfjs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee, faHome } from '@fortawesome/free-solid-svg-icons';
import "./App.css"

let courses = []
let finalCourses = []
let selectedCourse = ""
let exportDisable = true
let cselect = true
const selCo = (id) => {
  selectedCourse = id
  console.log("SELECTED COURSE: ", selectedCourse);
}
const Modal = ({ showModal, closeModal, c }) => {
  if (!showModal) return null;
  let courses = c

  return (
    <div className="modal1">
      <div className="modal1-content">
        <span className="close1" onClick={closeModal}>&times;</span>
        <div className="modal1-header">Elige un curso para medir el tiempo</div>
        <div className="modal1-body">
          {courses.map((course, index) => (
            <button className="btn1-course" key={index} onClick={selCo(course.uid)} >
              {course.name}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

function App() {
  const [isDisabled, setIsDisabled] = useState(true);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  //const [recognitionStartTime, setRecognitionStartTime] = useState(Date.now());
  const [recognitionStartTime, setRecognitionStartTime] = useState(Math.floor(Date.now()));
  const currentlyTime = useRef(Date.now());
  const time = useRef(Date.now());
  const [ti, setTi] = useState(0);
  const [recognitionDuration, setRecognitionDuration] = useState(0);

  let personas = ["unknown"];
  const [CurrenteName, setCurrenteName] = useState('');
  const [showModal, setShowModal] = useState(true);
  const [selectCourseModal, setSelectCourseModal] = useState(true);




  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  }


  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const handleButtonClick = () => {
    toggleModal()
  };

  const toggleModal1 = (uid) => {
    selectedCourse = uid
    // setShowModal(!selectCourseModal);
    exportDisable = false
    cselect = false

  };
  const handleButtonClick1 = (uid) => {
    toggleModal1(uid)

  };


  const getInitialWidth = () => {


    if (window.innerWidth < 480 && window.innerWidth > 300) {
      return 300;
    } else if (window.innerWidth <= 768) {
      return 500;
    } else {
      return 600;
    }
  }
  const getInitialHeight = () => {
    if (window.innerWidth <= 480 && window.innerWidth >= 300) {
      return 150;
    } else if (window.innerWidth <= 768) {
      return 350;
    } else {
      return 450;
    }
  }
  const [width, setWidth] = useState(getInitialWidth())
  const [height, setHeight] = useState(getInitialHeight())

  const exportTime = async () => {
    const uid = auth.currentUser.uid;
    const collectionRef = apps.firestore().collection("users");
    const docRef = collectionRef.doc(uid);
    const docSnapshot = await docRef.get();
    const fetchDoc = docSnapshot.data();
    const colectionCourses = apps.firestore().collection("courses");
    const docRefC = colectionCourses.doc(selectedCourse);
    const docSnapshotC = await docRefC.get();
    const fetchDocC = docSnapshotC.data();

    const timestamp = new Date();

    const collectionClassStudent = apps.firestore().collection("classStudent");
    const docRefCS = collectionClassStudent.doc(selectedCourse);
    const newClassStudent = {
      idStudent: uid,
      idCourses: fetchDocC.uid,
      nameStudent: fetchDoc.display_name,
      nameCourse: fetchDocC.name,
      timeInScreen: formatTime(recognitionDuration), // En minutos, por ejemplo
      date: timestamp// Fecha actual
    };
    await docRefCS.set(newClassStudent)
    console.log(selectedCourse);

  }

  const getCurrentUser = () => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);

    });
    return () => unsubscribe();
  };


  useEffect(() => {
    getCurrentUser();
  }, []);



  useEffect(() => {
    let interval;

    let isPaused = false;
    const loadModelsAndStartWebcam = async () => {
      try {
        await Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
          faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
          faceapi.nets.faceLandmark68Net.loadFromUri("/models"),
        ]);
        console.log("Modelos cargados");
        startWebcam();
      } catch (error) {
        console.error("Error cargando modelos: ", error);
      }
    };

    const startWebcam = async () => {

      navigator.mediaDevices
        .getUserMedia({
          video: { width: 320, height: 240 }, // Reducimos la resolución para mejorar la velocidad.
          audio: false,
        })
        .then(async (stream) => {
          const video = videoRef.current;
          video.srcObject = stream;

          video.addEventListener("playing", async () => {
            console.log("Video playing");
            try {
              console.log("Calling faceRecognition...");
              await faceRecognition(video);
              console.log("faceRecognition completed");
            } catch (error) {
              console.error('Error during face recognition:', error);
            }
          });
        })
        .catch((error) => {
          console.error("Error accediendo a la cámara: ", error);
          switch (error.name) {
            case "NotAllowedError":
              alert("Permiso para acceder a la cámara denegado.");
              break;
            case "NotFoundError":
              alert("No se encontró la cámara.");
              break;
            case "NotReadableError":
              alert("La cámara está en uso por otra aplicación.");
              break;
            case "OverconstrainedError":
              alert("Las restricciones especificadas no pueden ser satisfechas por ninguna cámara disponible.");
              break;
            default:
              alert("Ocurrió un error desconocido: " + error.message);
          }
        });
    };

    const fetchFaceData = async () => {
      try {
        const uid = auth.currentUser.uid;
        const collectionRef = apps.firestore().collection("archivos");
        const collectionUserRegistered = apps.firestore().collection("registered_users");


        const docRef = collectionRef.doc(uid);
        const docSnapshot = await docRef.get();
        const fetchDoc = docSnapshot.data();
        const labels = fetchDoc.familyNames;

        personas = labels;

        const collectionUsers = apps.firestore().collection("users");
        const docUSRef = collectionUsers.doc(uid);
        const fetchDocUser = (await docUSRef.get()).data();

        courses = fetchDocUser.courses
        let cs = []
        const c = apps.firestore().collection("courses");
        const promises = courses.map(async (uid) => {
          const d = c.doc(uid);
          const f = (await d.get()).data();
          return f; // Devuelve el resultado para cada promesa
        });

        // Espera a que todas las promesas se resuelvan
        const results = await Promise.all(promises);

        // Llena el array cs con los resultados
        cs.push(...results);

        finalCourses = cs

        if (finalCourses.length <= 0) {

        }
        courses = cs
        return Promise.all(
          labels.map(async (label) => {
            const docRefUser = collectionUserRegistered.doc(label.userRegRef);
            //const docRefUser = collectionUserRegistered.doc('nombre', '==', label);


            const docSnapshotUser = await docRefUser.get();
            const fetchDocUser = docSnapshotUser.data();


            const namePerson = fetchDocUser.nombre;
            const imagePathes = fetchDocUser.imageUrl;
            const descriptions = [];

            for (let i = 0; i < imagePathes.length; i++) {
              const img = await faceapi.fetchImage(imagePathes[i]);
              const detections = await faceapi
                .detectSingleFace(img, new faceapi.TinyFaceDetectorOptions({ inputSize: 160 })) // Reducimos el tamaño de entrada para más velocidad.
                .withFaceLandmarks()
                .withFaceDescriptor();

              if (detections && detections.descriptor) {
                //console.log("Path detectados: ",imagePathes[i] );


                descriptions.push(detections.descriptor);

              } else {
                const ef = labels.filter(item => item.name !== label.name)

                await docRef.update({
                  //familyNames: [docRegUserRef.id],
                  familyNames: ef
                })
                console.log(`No se detectó ninguna cara en la imagen: ${imagePathes[i]}`);
                alert(`No se detectó ninguna cara en la imagen con nombre: ${label.name}, vuelve a subirla `);
                window.location.reload();





              }
            }
            return new faceapi.LabeledFaceDescriptors(namePerson, descriptions);
          })
        );
      } catch (error) {
        console.error("Error fetching face data: ", error);
      }
    };



    const faceRecognition = async (video) => {

      const labeledFaceDescriptors = await fetchFaceData();
      const faceMatcher = new faceapi.FaceMatcher(labeledFaceDescriptors);

      const canvas = faceapi.createCanvasFromMedia(video);
      canvas.style.position = "absolute";
      canvas.style.top = 0;
      canvas.style.left = 0;
      canvas.style.zIndex = 1; // Asegura que el canvas esté sobre el video
      video.parentElement.appendChild(canvas);
      canvasRef.current = canvas;

      const displaySize = { width: video.width, height: video.height };
      faceapi.matchDimensions(canvas, displaySize);

      const processFrame = async () => {
        const detections = await faceapi
          .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions({ inputSize: 160, scoreThreshold: 0.5 }))
          .withFaceLandmarks()
          .withFaceDescriptors();

        const resizedDetections = faceapi.resizeResults(detections, displaySize);

        const context = canvas.getContext("2d");
        context.clearRect(0, 0, canvas.width, canvas.height);

        const results = resizedDetections.map((d) => faceMatcher.findBestMatch(d.descriptor));
        let dn = Date.now()

        if (results.length > 0 && results[0]._label !== "unknown") {
          // Si se reconoce una cara conocida

          if (recognitionStartTime !== null && currentlyTime !== null) {
            //setRecognitionStartTime(Date.now());
            // Establece un intervalo para actualizar la duración
            let durationInMs;
            let durationInMinutes;
            dn = Math.floor(dn)
            //const rest = Math.floor((dn - recognitionStartTime) / 1000)
            // console.log("DATE NOW", Math.floor(dn / 1000));
            time.current = dn
            durationInMs = (dn - recognitionStartTime) - (dn - currentlyTime.current);

            durationInMinutes = Math.floor(durationInMs / 1000);
            //console.log("DURATIONINMNUTES: ",durationInMinutes);
            // console.log("REST: ",rest);
            //setRecognitionDuration(durationInMinutes);

            if (!isPaused) {
              isPaused = true;
              interval = setInterval(() => {
                setRecognitionDuration(prev => prev + 1);

              }, 1000);

            }



          }

          setCurrenteName(results[0]._label);
        } else {
          // Si no hay caras reconocidas
          // setRecognitionStartTime(Date.now());
          // setRecognitionDuration(1);     
          isPaused = false;
          setTimeout(() => {
            clearInterval(interval);
            console.log('Intervalo detenido indefinidamente');
          });
          currentlyTime.current = time.current
          setCurrenteName("Rostro no registrado");
        }

        results.forEach((result, i) => {
          const box = resizedDetections[i].detection.box;
          const drawBox = new faceapi.draw.DrawBox(box, {
            label: result.toString(),
          });
          drawBox.draw(canvas);
        });

        requestAnimationFrame(processFrame);
      };

      requestAnimationFrame(processFrame);
    };

    loadModelsAndStartWebcam();

    return () => {
      clearInterval(interval)
      const canvas = canvasRef.current;
      if (canvas) {
        canvas.parentElement.removeChild(canvas);
      }
    };


  }, [recognitionStartTime]);

  return (
    <div className="all-container">
      {showModal && <div className="ModalUploadImage" id="modal">
        <div className="modal-content">
          <h2>Cargando modelos de IA</h2>
          <div className="loading-icon"></div>
          <button onClick={handleButtonClick} className="close-button" id="close">Close</button>

        </div>
      </div>}

      <div className="navbar">
        <div className="icon"> <FontAwesomeIcon icon={faHome} style={{ color: 'blue', fontSize: '30px' }} /></div>
        <nav className="textGoHome">
          <ul >
            <a href="https://login.visionguard.app/"><button id="upButton">Home</button>
            </a>
          </ul>
          <ul >
            <a href="https://login.visionguard.app/"><button id="upButton">Subir imagenes</button>
            </a>
          </ul>
          <ul >
            <a href="https://login.visionguard.app/"><button id="upButton">IP Remota</button>
            </a>
          </ul>
        </nav>
      </div>


      <div className="App" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", position: "relative" }}>


        <header className="App-header" >

          <div className="App-header-child" style={{ position: "relative" }}>



            <video className="tv" ref={videoRef} width={width} height={height} autoPlay >
              <canvas className="canva_screen" ref={canvasRef} ></canvas></video>


            {/*<canvas className="canva_screen" ref={canvasRef} style="position: absolute; top: 0; left: 0;"></canvas>*/}
            <div className="description">
              <div className="contain"><h6>Nombre encontrado: </h6><h6>{CurrenteName}</h6></div>
              {/*<div className="contain">
                <h6>Conecta Ip Remota</h6>
                <input type="text"></input>
              </div>*/ }
            </div>

            <div className="cronContainer1">
              <h2 className="bg-red-500">Temporizador</h2>
              <div className="watch1">
                <h5><span>Tiempo: </span>{(formatTime(recognitionDuration))} <button className="export-btn" onClick={exportTime} disabled={exportDisable}>Export</button></h5>
                {cselect && <div className="modal1">
              <div className="modal1-content">
                <div className="modal1-header"><p style={{
                  fontSize: '20px',         // Aumenta el tamaño de la fuente
                  color: '#333',             // Color de texto oscuro
                  padding: '10px 20px',      // Espaciado interno
                  borderRadius: '8px',       // Bordes redondeados
                  textAlign: 'center',       // Centrar el texto
                  fontFamily: 'Arial, sans-serif', // Cambiar fuente
                  fontWeight: 'bold'         // Negrita
                }}>Elige un grupo para medir el tiempo</p></div>
                <div className="modal1-body">
                  {finalCourses.map((course, index) => (
                    <button className="btn1-course" key={index} onClick={() => handleButtonClick1(course.uid)}  >
                      {course.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>}

              </div>
            </div>
          </div>

        </header>

        <div className="cronContainer">
          <h2>Temporizador</h2>
          <div className="watch">
            <h3>Tiempo</h3>
            <h1>{(formatTime(recognitionDuration))} </h1>

            <button className="export-btn" onClick={exportTime} disabled={exportDisable}>Export</button>
            {cselect && <div className="modal1">
              <div className="modal1-content">
                <div className="modal1-header"><p style={{
                  fontSize: '20px',         // Aumenta el tamaño de la fuente
                  color: '#333',             // Color de texto oscuro
                  padding: '10px 20px',      // Espaciado interno
                  borderRadius: '8px',       // Bordes redondeados
                  textAlign: 'center',       // Centrar el texto
                  fontFamily: 'Arial, sans-serif', // Cambiar fuente
                  fontWeight: 'bold'         // Negrita
                }}>Elige un grupo para medir el tiempo</p></div>
                <div className="modal1-body">
                  {finalCourses.map((course, index) => (
                    <button className="btn1-course" key={index} onClick={() => handleButtonClick1(course.uid)}  >
                      {course.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
    </div>

  );
}

export default App;
