export const drawRect = (detections, ctx) =>{
  // Loop through each prediction
  detections.forEach(prediction => {

    // Extract boxes and classes
    const [x, y, width, height] = prediction['bbox']; 
    const text = prediction['class']; 

    // Set styling
    const color = Math.floor(Math.random()*16777215).toString(16);
    ctx.strokeStyle = '#' + color
    ctx.font = '18px Arial';

    // Draw rectangles and text
    ctx.beginPath();   
    ctx.fillStyle = '#' + color
    ctx.fillText(text, x, y);
    ctx.rect(x, y, width, height); 
    ctx.stroke();
  });
}

//reconocimiento de objetos
export const drawRectObjects = (detections, ctx) =>{
  detections.forEach (prediction=>{
  // Get prediction results
  const [x,y,width, height] = prediction ['bbox'];
  const text = prediction ['class'];
  // set styling
  const color = 'green'
  ctx.strokeSylt = color
  ctx.font = '18px Arial'
  ctx.fillstyle = color
  // Draw retangles and text
  ctx.beginPath()
  ctx.fillText(text, x, y)
  ctx.rect(x, y, width, height)
  ctx.stroke()

  })}