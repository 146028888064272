import React, { useState, useEffect } from 'react';
import { apps } from './config/firebaseAll';
import { auth, app } from './config/firebase'; // Importa el módulo firebase.j
import { Link } from 'react-router-dom';
import './modals/upload_image.css'
import './Estilos.css';
import {uploadimage} from './modals/upload_image';

const StorageUpload = () => {

  const [archivo, setArchivo] = useState(null);
  const [archivoTwo, setArchivoTwo] = useState(null);
  const [nameImage, setNameImage] = useState("no_name");
  const [currentUser, setCurrentUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleArchivoChange = (event) => {
    setArchivo(event.target.files[0]);
  };

  const handleArchivoChangeImg2 = (event) => {
    setArchivoTwo(event.target.files[0]);
  };

  const subirImagen = async () => {
    console.log("archivo", archivo);
    console.log("archivo", archivoTwo);
    if (archivo && archivoTwo && nameImage != "no_name") {
      const alas = [nameImage];
      //const rutaCarpeta = 'Tico/';
      const rutaCarpeta = nameImage;
      const uid = await currentUser.uid;
        
      const storageRef = await apps.storage().ref(uid + "/" + rutaCarpeta);

      //const storageRef = apps.storage().ref(uid);
     
      
      //const archivoPath = storageRef.child("1");
      const archivoPath = await storageRef.child("1");
      const archivoPathTwo = await storageRef.child("2");
      const collectionUserRegisteredRef = await apps.firestore().collection("registered_users");
      const collectionRegisteredUsersRef = await apps.firestore().collection("users");
      const RegisteredUsersRef = await apps.firestore().collection("registeredUsersRef");
      //const UsRef = (await collectionRegisteredUsersRef.doc(uid).get()).data().registeredUsersRef
      
      const docRegUsersRef = await RegisteredUsersRef.doc()
      const docRegUsersRefData = await docRegUsersRef.get() ;
      const docRegUserRef = await collectionUserRegisteredRef.doc()
      const imgs = [];
      let i = ""
      const collectionRef = await apps.firestore().collection("archivos");
      const docRef = collectionRef.doc(uid);
      const docData = await docRef.get();
      //console.log("RefList: ",(await docRegUsersRef.get()));
      //let refList = (await docRegUsersRefData.data().refList) || []
     // console.log("RefList: ",refList);
      let existingFamilyNames = (await docData.data().familyNames) || [];
      let count = 0
      await archivoPath.put(archivo).then(async snapshot => {
        count+=1
        console.log('Archivo 1 subido exitosamente');
      }).catch(error => {
        console.error('Error al subir el archivo: de imagen 1', error);
      });
      i = await archivoPath.getDownloadURL();
         
    await imgs.push(i)
      await archivoPathTwo.put(archivoTwo).then(async snapshot => {
        console.log('Archivo 2 subido exitosamente');
        count+=1
      }).catch(error => {
        console.error('Error al subir el archivo de imagen 2', error);
      }); 
      i = await archivoPathTwo.getDownloadURL();
      await imgs.push(i)
      await docRegUserRef.set({
        imageUrl: imgs,
        nombre: nameImage,
        refUser: uid
      }, { merge: true }); 
        existingFamilyNames.push({name:nameImage,userRegRef:docRegUserRef.id})
        
      await docRef.update({
        //familyNames: [docRegUserRef.id],
        familyNames: existingFamilyNames
      })
        console.log(
        "Registered Users Ref: " ,docRegUsersRef.id
        );
        await docRegUsersRef.update({
          createdBy:uid,
          refList : [docRegUserRef.id],
          uid:uid
        }, { merge: true }); 
        if(count ==2){
          alert("Archivos subidos")
        }
    } else {
      //alert('Selecciona un archivo antes de subirlo.');
      toggleModal(showModal)
    }
  };

  const getCurrentUser = () => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      console.log(user.uid);
    });

    // Limpia la suscripción al desmontar el componente
    return () => unsubscribe();
  }

  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    //CSS está en Estilos.css
      <div className="screen-upload">
       {showModal && <div class="ModalUploadImage" id="modal">
        <div className="modal-content">
            <h2>Subir imagenes</h2>
            <p>Por favor carga dos imagenes y añadele un nombre para seguir el proceso.</p>
            <button onClick={toggleModal} className="close-button" id="close">OK</button>
        </div>
    </div>}
    <div className="container">
    <div id="LogoVisionGuard_img"><img src="logoVisionGuard.png" alt="Logo Vision Guard"></img></div>
      <h1 className="Tittle-Upload">Subir Imagenes</h1>
      <div className="input-group">
        <input type="file" accept=".jpg, .jpeg, .png"  onChange={handleArchivoChange} />
      </div>
      <div className="input-group">
        <input type="file" onChange={handleArchivoChangeImg2} />
      </div>
      <input className='placeh' placeholder="Nombre de quien son las imagenes" onChange={(e) => setNameImage(e.target.value)} />
      <button className="button" onClick={subirImagen}>Subir Imagen</button>
      <div>
        <nav>
          <ul>
            <li><Link to={'/facerecognition'}>Siguiente</Link></li>
          </ul>
        </nav>
      </div>
      <div>
            <nav>
                <ul>
                    <Link to={'/'} >Volver a Home</Link>   
                </ul>
            </nav>
        </div>
    </div>        
      </div>
  );
};

export default StorageUpload;