import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import RecObj from './RecObjects';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Login from './Login'; 
import StorageUpload from './StorageUpload';

const router = createBrowserRouter([
  {
  path: "/", 
  element: <Login/>,
  },
  {
    path: "/upload", 
    element: <StorageUpload/>,
  },
  {
    path: "/facerecognition", 
    element: <App/>,
  },
  {
    path: "/objectrecognition", 
    element: <RecObj/>,
  },
])


ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router = {router}/>
  </React.StrictMode>,
  document.getElementById('root')
);