import React from 'react';
import { Auth } from './components/auth';
import './Login.css';




function Login() {
    return <div className="Login">
    <div id="infoLogo">
    
      <h1 >VisionGuard Login</h1>
      <div id="LogoVisionGuardimg" ><img src="logoVisionGuard.png" alt="Logo Vision Guard"></img>
      </div>
    </div>
    <div id="authlogin"> <Auth/> </div>
    </div>;

}

export default Login;