import React, { useState } from 'react';
import { auth, googleProvider } from '../config/firebase';
import { apps } from '../config/firebaseAll';
import '../Estilos.css';
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { Link, Navigate, useNavigate } from 'react-router-dom';


import './Auth.css'; // Importa el archivo CSS

export const Auth = () => {
  
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  console.log(auth?.currentUser?.email);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleModal2 = () => {
    setShowModal2(!showModal2);
console.log(showModal2);
    
  };

  const signUp = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {

        const collectionRef = apps.firestore().collection("archivos");
        const collectionUserRef = apps.firestore().collection("registeredUserRef");

        const nuevoDocumentoData = {
          familyNames: [],
        };

 
        const idCollection = userCredential.user.uid;
        await collectionRef.doc(idCollection).set(nuevoDocumentoData);
        const newDocRegisteredUsersRef = {
          createdBy: idCollection,
          refList : []
        };

        await collectionUserRef.doc(idCollection).set(newDocRegisteredUsersRef)
        console.log("Nuevo documento creado correctamente");

        const user = userCredential.user;
        console.log("Usuario creado exitosamente", user);
        alert('Registro exitoso');

      });
    } catch (error) {
      console.error(error);
    }
  };

  const signIn = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // La autenticación fue exitosa, y userCredential contiene información del usuario
        const user = userCredential.user;
        //console.log("Inicio de sesión exitoso", user);
        //alert('Inicio de sesión exitoso');
        //navigate('/facerecognition');
        // Puedes realizar acciones adicionales después del inicio de sesión aquí
        toggleModal()
      });
    } catch (error) {
      toggleModal2()
      console.log("Errror iniciando");
     
    }
  };

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      alert('Sign In exitoso');
    } catch (error) {
      console.error(error);
    }
  };

  
  const handleButtonClick = () => {
    navigate('/facerecognition');
  };

  const handleButtonClick2 = () => {
    toggleModal2(!showModal2)
    
  };

  const logout = async () => {
    try {
      await signOut(auth);
      alert('Log Out exitoso');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="auth-container">
             {showModal && <div className="ModalUploadImage" id="modal">
        <div className="modal-content">
            <h2>Inicio de sesión exitoso</h2>
            <p>Digite ok para continuar</p>
            <button onClick={handleButtonClick} className="close-button" id="close">OK</button>
        </div>
    </div>}

    {showModal2 && <div className="ModalUploadImage" id="modal1">
        <div className="modal-content">
            <h2>Credenciales incorrectas</h2>
            <p>Porfavor digite un usuario y contraseña correctos</p>
            <button onClick={handleButtonClick2} className="close-button" id="close1">OK</button>
        </div>
    </div>}
      <div className="auth-container-sub">
      <div className= "imageLogo" ><img src="logoVisionGuard.png" alt="Logo Vision Guard"></img></div>
      <input
        className="auth-input"
        placeholder="Email..."
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        className="auth-input"
        placeholder="Password..."
        onChange={(e) => setPassword(e.target.value)}
        type="password"
      />
{/* 
  <button className="auth-signin" onClick={signUp}>
    Sign Up
  </button> 
*/}

      <button className="auth-button" onClick={signIn}>
        Sign In
      </button>


      <button className="auth-button" onClick={logout}>
        Log Out
      </button>
      </div>
    </div>
  );
};
